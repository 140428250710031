import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./App.css";
function App() {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState({});
  // Function to create a unique identifier for each course
  const getCourseKey = (course) => `${course.Kurs}-${course.Typ}`;
  const url = "data2.json";
  // Function to handle when a checkbox is toggled
  const handleCheck = (course, isChecked) => {
    setSelectedCourses((prevSelected) => {
      // Clone the previous state
      const newSelected = { ...prevSelected };
      const courseKey = getCourseKey(course);

      // Add or remove the course ECTS from the state based on whether it's checked
      if (isChecked) {
        newSelected[courseKey] = course.ECTS;
      } else {
        delete newSelected[courseKey];
      }

      return newSelected;
    });
  };
  // Calculate the sum of selected ECTS
  const sumOfECTS = Object.values(selectedCourses).reduce(
    (sum, ects) => sum + Number(ects),
    0
  );
  useEffect(() => {
    async function fetchData() {
      const res = await fetch(url);
      const data = await res.json();
      setLoading(false);
      setCourses(data.modules);
      console.log(data);
    }
    fetchData();
  }, []);
  return (
    <div className="App">
      <div className="header">
        <Container className="bg-info">
          <Row>
            <Col xs={4} className="textheader">
              Kurs
            </Col>
            <Col xs={1}>Typ</Col>
            <Col xs={1}>ECTS</Col>
            <Col xs={1}>Semester</Col>
            <Col xs={3}>Erledigt</Col>
          </Row>
        </Container>
      </div>
      <br></br>
      <div className="total">
        Total ECTS
        <br></br>
        {sumOfECTS}
      </div>
      {courses.map((mod, index) => {
        return (
          <div key={`module-${index}`}>
            {" "}
            {/* Added a unique key here */}
            <h3>{mod.moduleName}</h3>
            {mod.courses.map((modd, idx) => {
              console.log(mod.moduleName);
              const courseKey = getCourseKey(modd);
              const isChecked = selectedCourses.hasOwnProperty(courseKey);
              const rowClass = isChecked ? "selectedRow" : "";
              return (
                <div className={rowClass} key={courseKey}>
                  <Container>
                    <Row>
                      <Col xs={4}>
                        <p>{modd.Kurs}</p>
                      </Col>
                      <Col xs={1}>{modd.Typ}</Col>
                      <Col xs={1}>{modd.ECTS}</Col>
                      <Col xs={2}>{modd.Semester}</Col>
                      <Col xs={1}>
                        <Form.Check
                          checked={isChecked}
                          onChange={(e) => handleCheck(modd, e.target.checked)}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default App;
